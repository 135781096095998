var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-0 pa-2 pt-1"},_vm._l((_vm.invoices),function(invoice){return _c('v-card',{key:invoice.code,staticClass:"ma-0 mt-1 pa-0",attrs:{"to":{ name: 'InvoiceDetail', params: { id: invoice.id } },"no-gutters":"","hover":"","link":"","outlined":"","rounded":""}},[_c('v-card-text',[_c('v-row',{staticClass:"invoice-row d-flex pa-1"},[_c('v-col',{staticClass:"align-center ma-0 pa-0 pt-1 col-code"},[_vm._v(_vm._s(invoice.code))]),_c('v-col',{staticClass:"align-center ma-0 pa-0 pt-1 col-created"},[_vm._v(_vm._s(_vm.formatDate(invoice.created)))]),_c('v-col',{staticClass:"align-center ma-0 pa-0 pt-1 col-contact"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatName(invoice.contactName))+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatNameMultiline(invoice.contactName))}})])],1),_c('v-col',{staticClass:"align-center ma-0 pa-0 pt-1 col-price"},[_vm._v(" "+_vm._s(Number((invoice.priceSum).toFixed(2)))+" Kč "),(invoice.payed != null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green lighten-1"}},[_vm._v("mdi-check-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Payed "+_vm._s(_vm.formatDate(invoice.payed)))])]):(_vm.isDelayedWithPayment(invoice))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-exclamation-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Unpaid - delayed with payment!")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Yet unpaid!")])])],1),_c('v-col',{staticClass:"align-center ma-0 pa-0"},[_c('v-container',{staticClass:"d-flex justify-end",attrs:{"pa-0":"","ma-0":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyInvoice(invoice.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"silver lighten-1"}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy")])]),(invoice.payed == null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.markAsPaid(invoice.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green lighten-2"}},[_vm._v("mdi-wallet-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark as paid")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.markAsUnpaid(invoice.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red lighten-2"}},[_vm._v("mdi-wallet-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark as unpaid")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteInvoice(invoice.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)],1)],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }