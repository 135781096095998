<template>
    <div>
        USER SETTINGS
    </div>
</template>

<script>
export default {
    name: 'UserSettings',
    mounted() {

    },
    data() {
        return {
            data: {}
        }
    },
    methods: {}
}
</script>
