var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"1200","outlined":"","raised":"","loading":_vm.loading}},[_c('v-card-title',[_c('v-text-field',{staticClass:"faktury-page-header",attrs:{"prefix":"Invoice","solo":"","type":"number","counter":"200"},model:{value:(_vm.invoiceData.code),callback:function ($$v) {_vm.$set(_vm.invoiceData, "code", $$v)},expression:"invoiceData.code"}})],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outline":"","raised":""}},[_c('v-card-text',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"pl-3 pr-3",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Created")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('Datetime',_vm._g(_vm._b({attrs:{"type":"date","value-zone":"local","input-class":"invoice-datetime"},model:{value:(_vm.invoiceData.created),callback:function ($$v) {_vm.$set(_vm.invoiceData, "created", $$v)},expression:"invoiceData.created"}},'Datetime',attrs,false),on))]}}])},[_c('span',[_vm._v("Click to change")])])],1)],1)],1),_c('v-card',{staticClass:"pl-3 pr-3 mt-1",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Pay until")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('Datetime',_vm._g(_vm._b({attrs:{"type":"date","value-zone":"local","input-class":"invoice-datetime"},model:{value:(_vm.invoiceData.payUntil),callback:function ($$v) {_vm.$set(_vm.invoiceData, "payUntil", $$v)},expression:"invoiceData.payUntil"}},'Datetime',attrs,false),on))]}}])},[_c('span',[_vm._v("Click to change")])])],1)],1)],1),_c('v-card',{staticClass:"pl-3 pr-3 mt-1",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Payed")]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('Datetime',_vm._g(_vm._b({attrs:{"type":"date","value-zone":"local","input-class":"invoice-datetime"},model:{value:(_vm.invoiceData.payed),callback:function ($$v) {_vm.$set(_vm.invoiceData, "payed", $$v)},expression:"invoiceData.payed"}},'Datetime',attrs,false),on))]}}])},[_c('span',[_vm._v("Click to change")])])],1),_c('v-col',{attrs:{"cols":"1"}},[(_vm.invoiceData.payed != null)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.markAsUnpaid.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":"","color":"red lighten-1"}},[_vm._v("mdi-delete")])],1)]}}],null,false,1154252922)},[_c('span',[_vm._v("Mark as unpaid")])]):_vm._e()],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('ContactBox',{attrs:{"code":_vm.entrepreneurData.code,"name":_vm.entrepreneurData.name,"address":_vm.entrepreneurData.address,"vat":_vm.entrepreneurData.vat}})],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"invoice-contact-box",attrs:{"cols":"4"},on:{"click":function($event){return _vm.$refs.ContactsDialog.show()}}},'v-col',attrs,false),on),[_c('ContactBox',{attrs:{"code":_vm.contactData.code,"name":_vm.contactData.name,"address":_vm.contactData.address,"vat":_vm.contactData.vat}}),_c('ContactsDialog',{ref:"ContactsDialog",on:{"contact-selected":_vm.contactSelected}})],1)]}}])},[_c('span',[_vm._v("Click to change")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"orange darken-1","text":""},on:{"click":_vm.downloadInvoice}},[_vm._v("Download")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.deleteInvoice}},[_vm._v("Delete")])],1)],1),_c('br'),_c('v-card',{attrs:{"outline":"","raised":""}},[_c('InvoiceRows',{attrs:{"rows":_vm.invoiceRows,"invoiceId":Number(_vm.$route.params.id)},on:{"row-deleted":_vm.rowDeleted,"row-updated":_vm.rowUpdated,"row-inserted":_vm.rowInserted}})],1)],1)],1),_c('form',{ref:"DownloadForm",attrs:{"action":_vm.downloadUrl,"method":"POST"}},[_c('input',{attrs:{"type":"hidden","name":"auth"},domProps:{"value":_vm.$storage.get('login-session')}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }